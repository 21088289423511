@import '../../styles/common/common.scss';

.root {
    width: 100%;
    max-width: rem(1920px);
    margin: auto;
    padding-top: rem(50px);
    padding-bottom: rem(50px);
    display: flex;
    align-items: center;

    @include bp(lg) {
        padding-top: rem(150px);
    }

    .container {
        width: 100%;
        margin: auto;
        max-width: rem(600px);
        display: flex;
        flex-wrap: wrap;

        @include bp(lg) {
            max-width: 100%;
        }

        .left_section {
            position: relative;
            width: 85%;
            margin: auto;

            @include bp(lg) {
                width: 55%;
                margin: 0;

                &::before {
                    position: absolute;
                    z-index: -1;
                    content: '';
                    top: 0;
                    right: rem(-120px);
                    width: rem(400px);
                    height: 100%;
                    background-color: var(--utmb-color-white);
                    transform: skew(-20deg);
                }
            }

            .content_wrapper {
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                @include bp(lg) {
                    padding: rem(30px);
                    max-width: rem(800px);
                    margin: auto;
                }

                .heading_second {
                    margin-bottom: rem(20px);

                    @include bp(lg) {
                        margin-bottom: 0;
                    }
                }

                .summary_cta_wrapper {
                    @include bp(lg) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .summary {
                        max-width: rem(350px);
                        margin-bottom: rem(20px);

                        @include bp(lg) {
                            margin-bottom: 0;
                        }
                    }

                    .cta {
                        width: 100%;
                        margin-top: rem(40px);
                        margin-bottom: rem(70px);

                        @include bp(lg) {
                            width: auto;
                            margin-top: 0;
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .right_section {
            position: relative;
            width: 100%;
            height: rem(400px);
            z-index: -2;

            @include bp(lg) {
                height: rem(600px);
                width: 45%;
            }
        }
    }
}
